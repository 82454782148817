import React, {useEffect, useState} from 'react';
import CurrencyInput from 'react-currency-input-field';
import moment from "moment";
import ReactGA from "react-ga";

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Calculator = ({setResults}) => {
    const [principalAmount, setPrincipalAmount] = useState()
    const [interestRate, setInterestRate] = useState()
    const [dayCount, setDayCount] = useState()
    const [withholding, setWithholding] = useState()
    const [startDate, setStartDate] = useState()
    const [error, setError] = useState(null)

    const CalculateInterset = (e) => {
        e.preventDefault()

        if (principalAmount !== undefined &&
            interestRate !== undefined &&
            dayCount !== undefined &&
            withholding !== undefined &&
            startDate !== undefined
        ) {
            setError(null)
            // const totalInterestIncome = (principalAmount / 100)*(interestRate/360)*dayCount
            const totalInterestIncome = principalAmount * interestRate * dayCount / 36500
            const withholdingDeduction = totalInterestIncome * (withholding / 100)
            const netInterestIncome = totalInterestIncome - withholdingDeduction
            setResults({
                "Ana Para": `${currencyFormat(principalAmount)} TL`,
                "Vade Sonu Varlık": `${currencyFormat(principalAmount + netInterestIncome)} TL`,
                "Bitiş Tarihi": moment(new Date(startDate)).add(dayCount, "days").format('DD/MM/YYYY'),
                "Faiz Getirisi": `${currencyFormat(totalInterestIncome)} TL`,
                "Stopaj Gesintisi": `${currencyFormat(withholdingDeduction)} TL`,
                "Net Faiz Getirisi": `${currencyFormat(netInterestIncome)} TL`
            })
            ReactGA.event({ category: "InterestCalculation", action: "Interset calculated" });
        }else{
            setError("Tüm alanları doldurunuz")
        }
    }

    return (
        <div>
            <div className="w-full text-center font-bold text-lg p-10">Mevduat Faizi Hesaplama</div>
            <div className="w-11/12 m-auto">
                <div className="flex flex-col gap-4">
                    <label className="block relative">
                        <span className="text-gray-900 font-bold">Ana Para</span>
                        <div className="relative flex justify-center items-center">
                            <CurrencyInput name="principalAmount" decimalsLimit={2}
                                           className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"
                                           onValueChange={(value, name, values) => setPrincipalAmount(values.float)}
                            />
                            <div className="absolute right-0 mt-1 mr-2 text-gray-400 select-none text-sm">TL</div>
                        </div>
                    </label>
                    <div className="flex gap-6">
                        <label className="block">
                            <span className="text-gray-900 font-bold">Faiz Oranı</span>
                            <div className="relative flex justify-center items-center">
                                <CurrencyInput name="interestRate" decimalsLimit={2}
                                               className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"
                                               onValueChange={(value, name, values) => setInterestRate(values.float)}
                                />
                                <div className="absolute right-0 mt-1 mr-2 text-gray-400 select-none text-sm">%</div>
                            </div>
                        </label>
                        <label className="block">
                            <span className="text-gray-900 font-bold">Gün</span>
                            <div className="relative flex justify-center items-center">
                                <input type="tel" name="dayCount"
                                       onChange={(e) => setDayCount(parseInt(e.target.value))}
                                       className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"
                                       onKeyPress={(event) => {
                                           if (!/[0-9]/.test(event.key)) {
                                               event.preventDefault();
                                           }
                                       }}
                                />
                                {/*<CurrencyInput name="dayCount" decimalsLimit={0}*/}
                                {/*               className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"*/}
                                {/*               onValueChange={(value, name, values) => setDayCount(values?.float)}*/}
                                {/*/>*/}
                                <div className="absolute right-0 mt-1 mr-2 text-gray-400 select-none text-sm">Gün</div>
                            </div>
                        </label>
                        <label className="block">
                            <span className="text-gray-900 font-bold">Stopaj</span>
                            <div className="relative flex justify-center items-center">
                                <CurrencyInput name="withholding" decimalsLimit={0}
                                               className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"
                                               onValueChange={(value, name, values) => setWithholding(values.float)}
                                />
                                <div className="absolute right-0 mt-1 mr-2 text-gray-400 select-none text-sm">%</div>
                            </div>
                        </label>
                    </div>
                    <label className="block">
                        <span className="text-gray-900 font-bold">Başlangıç Tarihi</span>
                        <input type="date" name="startDate"
                               className="mt-1 block w-full rounded-md border-gray-100 focus:border-indigo-300 focus:ring focus:ring-blue-100 focus:ring-opacity-50"
                               onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    {error && <div className="text-red-500 font-light text-sm text-center">{error}</div> }
                    <button className="bg-gray-900 p-3 rounded-md text-white" onClick={CalculateInterset}>Hesapla</button>
                </div>
            </div>
        </div>
    );
};

export default Calculator;