import React from 'react';

const Results = ({results}) => {
    return results !== undefined && (
        <div className="w-11/12 m-auto flex flex-col gap-4 mt-4">
            {
                Object.entries(results).map(([k, v], i) =>
                    <div className="bg-white flex justify-between items-center p-4 rounded-md" key={i}>
                        <div className="font-medium">{k}</div>
                        <div className={`${k === "Net Faiz Getirisi" ? "font-bold" : "font-light"}`}>{v}</div>
                    </div>
                )
            }
        </div>
    );
};

export default Results;