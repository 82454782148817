import {useState} from "react";
import Calculator from "../Components/Calculator/Calculator";
import Results from "../Components/Results/Results";
import Footer from "../Components/Footer/Footer";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-XDHDP51P27"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    const [results, setResults] = useState()

    return (
        <div className="bg-gray-100 min-h-screen">
            <Calculator setResults={setResults}/>
            <Results results={results}/>
            <Footer />
        </div>
    );
}

export default App;
