import React from 'react';
import Logo from "../../assets/imgs/ozware-light.png"

const Footer = () => {
    return (
        <>
        <div className="w-11/12 m-auto py-4 text-gray-400 font-light">
            <div className="text-[0.7rem]">*Bu platform, yalnızca hesaplama amaçlıdır ve girilen bilgiler kaydedilmemektedir.Hesaplamalar, BDDK formülüne dayanır ve banka ile dönemsel koşullara göre değişebilir.</div>
        </div>
        <div className="w-full bg-ozware-dark">
            <div className="w-11/12 m-auto text-white p-4">
                <img src={Logo} alt="Ozware"/>
            </div>
        </div>
        </>
    );
};

export default Footer;